import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Navigation from '../components/Navigation';
import Footer from '../sections/Footer';
import faviconPath from '../images/favicon.ico';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  height: 100%;
  justify-content: space-between;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: rgba(0, 0, 0, 0.870588);
  user-select: none;
`;

const DescriptionContainer = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
`;

const ExtraSmallHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.870588);
  display: flex;
`;

const GoalHeading = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: rgba(0, 0, 0, 0.870588);
  align-items: center;
  display: flex;
  margin-top: 25px;
`;


const AboutUs = () => (
  <Container>
    <Helmet>
      <meta charSet="utf-8" />
      <title>About Us - Bidmatik</title>
      <link rel="canonical" href="https://bidmatik.com/aboutus" />
      <link rel="shortcut icon" type="image/png" href={faviconPath} />
    </Helmet>
    <Navigation />
    <Center>
      <Heading>
        Pushing the boundaries
        <br />
        of Ad technology
      </Heading>
      <DescriptionContainer>
        <GoalHeading>
          What we do:
        </GoalHeading>
        <ExtraSmallHeading>
          We use technology to make advertising more effective.
        </ExtraSmallHeading>

        <GoalHeading>
          Our goal is to:
        </GoalHeading>
        <ExtraSmallHeading>
          Deliver more relevant ads to customers.
        </ExtraSmallHeading>
        <ExtraSmallHeading>
          And increase global commerce through targeted advertising.
        </ExtraSmallHeading>
      </DescriptionContainer>
    </Center>
    <Footer showBackground />
  </Container>
);

export default AboutUs;
